export default ['$window', '$rootScope', 'bpeCoreLicensingConstants', controller]

function controller($window, $rootScope, bpeCoreLicensingConstants) {
  this.sessionExpired = $window.localStorage.getItem('sessionExpired') === 'true'
  this.sessionExpiredId = $window.localStorage.getItem('sessionExpiredId')
  this.licenseNotFound = $window.localStorage.getItem('licenseNotFound') === 'true'
  $window.localStorage.removeItem('sessionExpired')
  $window.localStorage.removeItem('sessionExpiredId')
  $window.localStorage.removeItem('licenseNotFound')
}
