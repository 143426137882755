import template from './bpe-licensing-trial.tmpl.html'
import styles from './bpe-licensing-trial.scss'
/**
 * bpeLicensingTrial
 */
const component = {
  template,
  styles,
  bindings: {
    behavior: '<?',
    stateTo: '@?',
    timeoutSeconds: '<?',
  },
  controller: [
    '$rootScope',
    '$timeout',
    'bpeLicensingService',
    'bpeCoreLicensingConstants',
    controller,
  ],
}

export default component

function controller($rootScope, $timeout, bpeLicensingService, bpeCoreLicensingConstants) {
  const $ctrl = this

  $ctrl.$onInit = async () => {
    $ctrl.showTrialMessage = false
    $ctrl.showNoLicenseMessage = false

    $ctrl.dismissTrialMessage = () => {
      $ctrl.showTrialMessage = false
    }
 

    if ($ctrl.behavior === bpeCoreLicensingConstants.behaviors.DISMISS) {
      $ctrl.canDismissTrial = true
    }

    }

 
  
}
