import { BpeServiceBase } from '@libs/http-services'
export class bpeLicensingService extends BpeServiceBase {
  constructor(bpeHttpService, $q, Upload) {
    'ngInject'
    super('/', bpeHttpService, $q, Upload)
    Object.assign(this, {
      bpeHttpService,
      $q,
      Upload,
    })
  }

  getCarrierLicense() {
    return super.get('/License/GetCarrierLicense')
  }

  getLicenseInfo() {
    return super.get('/License/GetLicenseByCustomerInfo')
  }

  refreshLicenseInfo() {
    return super.get('/License/Refresh')
  }

  refreshExternalLicenseInfo() {
    return super.get('/License/RefreshExternalLicense')
  }

  getCarrierExternalLicense(licenseTypeCode) {
    return super.get('/License/GetCarrierExternalLicense?licenseTypeCode=' + licenseTypeCode)
  }

  getB2BLicenseInfo() {
    return super.get('/License/GetB2BLicenseByCustomerInfo')
  }

  // This method is executed in app.run file
  // async validate() {
  //   try {
  //     return await this.bpeHttpService.get('/License/Info')
  //   } catch (error) {
  //     return null
  //   }
  // }
  async validate() {
    return await this.bpeHttpService.get('/License/Info')
  }
}

/**
 * bpeLicensingService
 * */
// export default [
//   '$q',
//   '$rootScope',
//   '$window',
//   'bpeHttpService',
//   'bpeCoreLicensingConstants',
//   service,
// ]

// function service($q, $rootScope, $window, bpeHttpService, bpeCoreLicensingConstants) {
//   const svc = this

//   let loadedLicenseInfo = false

//   svc.licenseInfo = {}

//   svc.setLicenseInfo = async (licenseInfo) => {
//     loadedLicenseInfo = true
//     svc.licenseInfo = licenseInfo
//   }

// svc.validate = async () => {
// await svc.getCurrentLicenseInfo()
// await bpeHttpService.get('/License/Info')
// if (!svc.licenseInfo?.licenseKey) {
// $rootScope.$emit(bpeCoreLicensingConstants.events.BPE_NO_LICENSED)
//$window.localStorage.setItem('licenseNotFound', 'true')
//utilities.signOut()
// }

// if (
//   !svc.licenseInfo.licenseType ||
//   svc.licenseInfo.licenseType === bpeCoreLicensingConstants.licenseTypes.TRIAL_PERIOD
// ) {
//   $rootScope.$emit(bpeCoreLicensingConstants.events.BPE_TRIAL_WARNING)
// }
// }

// svc.getCurrentLicenseInfo = async () => {
//   if (loadedLicenseInfo) {
//     return svc.licenseInfo
//   }
//   return svc.refresh()
// }

// svc.refresh = async () => {
//   const licenseInfoResponse = await bpeHttpService.get('/License/Info')
//   if (licenseInfoResponse?.success) {
//     svc.setLicenseInfo(licenseInfoResponse.items[0])
//     return svc.licenseInfo
//   } else {
//     svc.setLicenseInfo(undefined)
//     return svc.licenseInfo
//   }
// }

// svc.getLicenseInfo = async () => {
//   return await bpeHttpService.get('/License/GetLicenseByCustomerInfo')
// }

// svc.getCarrierLicense = async () => {
//   return await bpeHttpService.get('/License/GetCarrierLicense')
// }
// }
