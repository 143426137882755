/**
 * Represents a base implementation of a service call to the
 * web API layer using the bpeHttpService implementation.
 * @deprecated Since we are using the RestFul Api going forward.
 */
export class BpeContextServiceBase {
	constructor(bpeServiceBase, { entityName = 'entity' }) {
		Object.assign(this, { bpeServiceBase, entityName });
	}

	/**
	 * Gets a single entity based on its identifier.
	 * @param {int} id - The identifier of the single entity to fetch from the service.
	 */
	async get(...parameters) {
		this[this.entityName] = await this.bpeServiceBase.get(parameters);
		return this[this.entityName];
	}

	/**
	 * Gets a single entity based on its identifier.
	 * @param {int} id - The identifier of the single entity to fetch from the service.
	 */
	async postRequest(request, ...parameters) {
		this[this.entityName] = await this.bpeServiceBase.postRequest(request, parameters);
		return this[this.entityName];
	}

	/**
	 * Posts and saves the specified entity to the web service.
	 * @param {entity} entity - The entity to save.
	 */
	async save(...parameters) {
		return this.bpeServiceBase.save(this[this.entityName], parameters);
	}
}
