/**
 * Represents a base implementation of a service call to the
 * web API layer using the bpeHttpService implementation.
 */
export class BpeServiceBase {
  constructor(baseRouteUrl, bpeHttpService) {
    this.baseRouteUrl = baseRouteUrl
    this.bpeHttpService = bpeHttpService

    // if it starts with /api/v1 the service is using the new .NetCore service stack, we need to direct to "services"
    if (baseRouteUrl.toLowerCase().startsWith('/api/v1')) {
      this._httpConfig = {
        baseUrl: 'ng-svc',
      }
    }
  }

  get httpConfig() {
    return this._httpConfig
  }

  /**
   * Gets the optional name of the entity, this will be
   * appended to the Base Url if set. /baseurl/entity
   */
  get entityName() {
    return this._entityName
  }

  /**
   * Sets the optional name of the entity appended to the
   * Base Url
   */
  set entityName(value) {
    this._entityName = value
  }

  /**
   * Gets the base route from the baseRouteUrl provided in the constructor
   * and the optional entity name. This may be used for custom service
   * calls not currently supported by the base service class.
   * For example: '/getByClientId/{id}/someOtherThing.
   */
  get baseRoute() {
    let startUrlPart = ''
    if (this.entityName) {
      return `${startUrlPart}${this.baseRouteUrl}/${this.entityName}`
    } else {
      return `${startUrlPart}${this.baseRouteUrl}`
    }
  }

  /**
   * Gets a list of the entity specified from the service.
   */
  getList(...parameters) {
    let urlParams = this._getArgumentsAsParameters(parameters)
    return this.bpeHttpService.get(`${this.baseRoute}/getList${urlParams}`, this.httpConfig)
  }

  /**
   * Gets a list of the entity specified from the service.
   */
  list(...parameters) {
    let urlParams = this._getArgumentsAsParameters(parameters)
    return this.bpeHttpService.get(`${this.baseRoute}/list${urlParams}`, this.httpConfig)
  }

  /**
   * Gets a single entity based on its identifier.
   * @param {int} id - The identifier of the single entity to fetch from the service.
   */
  get(...parameters) {
    return this.bpeHttpService.get(this._buildUrlFromArguments(parameters), this.httpConfig)
  }

  /**
   * Posts and saves the specified entity to the web service.
   * @param {entity} entity - The entity to save.
   */
  save(entity, ...parameters) {
    return this.bpeHttpService.post(
      this._buildUrlFromArguments(parameters),
      entity,
      this.httpConfig
    )
  }

  /**
   * Posts the request object provided and returns the results. This is good for
   * requests with large sets of parameters to request. Essentially same as SAVE.
   * @param {object} request - DTO containing the object to make the request with.
   * @param {string} methodName - The additional name of the method to use when making the request
   * Example: myService.postRequest({customerId: 1, status: 'ACTIVE'})
   */
  postRequest(request, ...parameters) {
    return this.bpeHttpService.post(
      this._buildUrlFromArguments(parameters),
      request,
      this.httpConfig
    )
  }

  /**
   * Posts to the Url and returns the results.
   * @param {arguments} parameters - URL parameters to string together.
   */
  post(...parameters) {
    return this.bpeHttpService.post(this._buildUrlFromArguments(parameters), this.httpConfig)
  }

  /**
   * Put using the base Url and parameters provided.
   * @param {object} request - the request object to send as the request body.
   * @param  {...any} parameters - URL parameters to string together.
   */
  put(request, ...parameters) {
    return this.bpeHttpService.put(
      this._buildUrlFromArguments(parameters),
      request,
      this.httpConfig
    )
  }

  /**
   * Delete using the base Url and parameters provided.
   * @param {object} request - the request object to send as the request body.
   * @param  {...any} parameters - URL parameters to string together.
   */
  delete(request, ...parameters) {
    return this.bpeHttpService.delete(
      this._buildUrlFromArguments(parameters),
      request,
      this.httpConfig
    )
  }

  /**
   * Delete using the base Url and parameters provided.
   * @param {int} id - The identifier of the single entity to delete.
   */
  deleteId(...parameters) {
    return this.bpeHttpService.delete(this._buildUrlFromArguments(parameters))
  }

  /**
   * Patch using the base Url and parameters provide.
   * @param {object} request - the request object to send as the request body.
   * @param  {...any} parameters - URL parameters to string together.
   */
  patch(request, ...parameters) {
    return this.bpeHttpService.patch(
      this._buildUrlFromArguments(parameters),
      request,
      this.httpConfig
    )
  }

  /**
   * Builds a dynamic URL based on the arguments specified to the service.
   * For example: your-url/{arg1}/{arg2}/{arg3}
   */
  query(...parameters) {
    return this.bpeHttpService.get(this._buildUrlFromArguments(parameters), this.httpConfig)
  }

  /**
   * Builds a Url from the BaseRoute using the arguments specified in the format: "baseRoute/{arg1}/{arg2}";
   */
  _buildUrlFromArguments(parameters) {
    let urlParams = this._getArgumentsAsParameters(parameters)
    return `${this.baseRoute}${urlParams}`
  }

  _getArgumentsAsParameters(parameters) {
    if (!parameters || parameters.length === 0) {
      return ''
    }
    const args = Array.from(parameters)
    return `/${args.join('/')}`
  }
}
