import urlJoin from 'url-join'

export class UrlServicesService {
  constructor($location, $browser) {
    'ngInject'
    Object.assign(this, {
      $location,
      $browser,
    })
  }

  getBaseUrl() {
    let hostWithPort = this._getHostWithPort()
    let basePath = this.getBasePath()
    if (basePath) {
      return urlJoin(hostWithPort, basePath)
    }
    return hostWithPort
  }

  getBasePath() {
    const basePath = this.$browser.baseHref()
    if (basePath.trim() === '/') {
      return ''
    }
    return basePath
  }

  _getHostWithPort() {
    const protocol = this.$location.protocol()
    const host = this.$location.host()
    const port = this.$location.port()
    return port ? `${protocol}://${host}:${port}` : `${protocol}://${host}`
  }
}
