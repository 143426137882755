import './login.scss'

import angular from 'angular'
import bpeCoreHttpServices from '@libs/http-services'
import bpeCoreUrlServices from '@libs/url-services'
import bpeCookieServices from '@libs/cookie-services'
import bpeCoreLicensing from '@libs/licensing'

const module = angular.module('login', [
  bpeCoreHttpServices,
  bpeCoreUrlServices,
  bpeCookieServices,
  bpeCoreLicensing,
])

import loginConfig from './login.config'
module.config(loginConfig)

import loginController from './login.controller'
module.controller('loginController', loginController)
