import angular from 'angular';

function config($provide) {}

import bpeLegacyHttpService from './bpe-legacy-http.service';
import bpeHttpService from './bpe-http.service';

export const BpeHttpServiceModule = angular.module('bpeHttpService.module', [])
	.service('bpeLegacyHttpService', bpeLegacyHttpService)
	.provider('bpeHttpService', bpeHttpService)
	.config(['$provide', config])
	.name;
