import assign from 'lodash/assign'
import urlJoin from 'url-join'

function service(provider, $http) {
  const svc = this

  svc.configure = (options) => {
    assign(svc, options)
  }

  svc.getBaseUrl = () => {
    return svc.baseUrl || provider.baseUrl
  }

  svc.createRequest = (config = {}) => {
    const req = Object.assign({}, config)
    const baseUrl = config.baseUrl || svc.getBaseUrl()
    if (baseUrl) {
      req.url = urlJoin(baseUrl, req.url)
    }

    if (provider.preRequest) {
      provider.preRequest(req)
    }
    return req
  }

  svc.request = (config = {}) => {
    const req = svc.createRequest(config)
    return $http(req).then(
      function (response) {
        if (config.returnResponse) {
          return response
        }
        return response.data
      },
      function (error) {
        throw error
      }
    )
  }

  svc.login = (username, password, config = {}) => {
    config.headers = assign(config.headers, {
      'X-Bpe-Username': username,
      'X-Bpe-Password': password,
    })

    config.transformRequest = function (obj) {
      var str = []
      for (var p in obj) str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
      return str.join('&')
    }

    const authenticationPath = svc.authenticationPath || provider.authenticationPath

    return svc.post(authenticationPath, {}, config).then(function (identity) {
      return identity
    })
  }

  svc.get = (url, config) => {
    return methodRequest('GET', url, config)
  }

  svc.head = (url, config) => {
    return methodRequest('HEAD', url, config)
  }

  svc.delete = (url, config) => {
    return methodRequest('DELETE', url, config)
  }

  svc.jsonp = (url, config) => {
    return methodRequest('JSONP', url, config)
  }

  svc.post = (url, data, config = {}) => {
    assign(config, {
      data,
    })
    return methodRequest('POST', url, config)
  }

  svc.put = (url, data, config = {}) => {
    assign(config, {
      data,
    })
    return methodRequest('PUT', url, config)
  }

  svc.patch = (url, data, config = {}) => {
    assign(config, {
      data,
    })
    return methodRequest('PATCH', url, config)
  }

  function methodRequest(method, url, config) {
    let req = {
      method,
      url,
    }
    assign(req, config)
    return svc.request(req)
  }
}

function configurableService() {
  var provider = this
  provider.authenticationPath = '/Authentication/Login'
  provider.configure = function (options) {
    assign(provider, options)
  }
  provider.$get = [
    '$http',
    function ($http) {
      return new service(provider, $http)
    },
  ]
}

export default configurableService
