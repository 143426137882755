import truncate from 'lodash/truncate';
import endsWith from 'lodash/endsWith';
import has from 'lodash/has';



function service($q, bpeHttpService) {
	const svc = this;

	svc.HttpHelper = function(method, controllerAction, data, logError, dontShowOverlay, excludePayload, canceller,
		baseUrl) {
		var showOverlay;
		var bExcludePayload;

		if (dontShowOverlay === undefined) {
			showOverlay = false;
		} else {
			showOverlay = dontShowOverlay;
		}

		if (excludePayload === undefined) {
			bExcludePayload = false;
		} else {
			bExcludePayload = excludePayload;
		}

		var deferred = $q.defer();

		var requestConfig = {
			method: method,
			url: controllerAction,
			data: data,
			hideOverlay: showOverlay,
			timeout: canceller ? canceller.promise : undefined,
			baseUrl: baseUrl
		};

		bpeHttpService.request(requestConfig)
			.then(function(responseData) {
				if (bExcludePayload && has(responseData, 'payload')) {
					deferred.resolve(responseData.payload);
				} else {
					deferred.resolve(responseData);
				}
			}, function(response) {
				var err = response.data;
				var responseStatus = null,
					errdata = null;
				if (err) {
					if (err.hasOwnProperty('responseStatus')) {
						responseStatus = err.responseStatus;
					}
					errdata = err.exceptionMessage || err.message || err;
				}
				deferred.reject({
					data: errdata,
					code: response.status,
					responseStatus: responseStatus
				});
			});
		return deferred.promise;
	};

	svc.HttpHelperWithBaseUrl = function(baseUrl, method, controllerAction, data, logError, dontShowOverlay,
		excludePayload, canceller) {
		return svc.HttpHelper(method, controllerAction, data, logError, dontShowOverlay, excludePayload, canceller, baseUrl);
	};

	svc.HttpPDFHelper = function(method, controllerAction, data) {
		var deferred = $q.defer(),
			url = controllerAction;
		bpeHttpService.request({
				method: method,
				url: url,
				data: data,
				responseType: 'arraybuffer'
			})
			.success(function(responseData) {
				deferred.resolve(responseData);
			})
			.error(function(err, status) {
				var errdata = err.exceptionMessage || err.message || err;
				deferred.reject({
					data: errdata,
					code: status
				});
			});
		return deferred.promise;
	};

	svc.FixUrl = function(url) {
		if (endsWith(url, '/')) {
			var fix = truncate(url, {
				length: url.length - 1,
				omission: ''
			});
			return fix;
		}
		return url;

	};

	svc.createGetServiceCall = function(url) {
		return function(excludePayload) {
			return svc.HttpHelper('GET', svc.FixUrl(url), null, null, null, excludePayload);
		}
	}

	svc.createGetWithParamsServiceCall = function(url) {
		return function() {
			var args = Array.prototype.slice.call(arguments);
			var urlParams = args.join('/');
			return svc.HttpHelper('GET', svc.FixUrl(url) + '/' + urlParams, null);
		}
	}

	svc.createGetByIdServiceCall = function(url) {
		return function(id, excludePayload) {
			return svc.HttpHelper('GET', svc.FixUrl(url) + '/' + id, null, null, null, excludePayload);
		}
	}

	svc.createPostServiceCall = function(url) {
		return function(dto, excludePayload) {
			return svc.HttpHelper('POST', url, dto, null, null, excludePayload);
		}
	}

	svc.createPutServiceCall = function(url) {
		return function(dto, excludePayload) {
			return svc.HttpHelper('PUT', url, dto, null, null, excludePayload);
		}
	}

	svc.createPutByIdServiceCall = function(url) {
		return function(id, dto, excludePayload) {
			return svc.HttpHelper('PUT', svc.FixUrl(url) + '/' + id, dto, null, null, excludePayload);
		}
	}

	svc.createDeleteServiceCall = function(url) {
		return function(id, excludePayload) {
			return svc.HttpHelper('DELETE', svc.FixUrl(url) + '/' + id, null, null, null, excludePayload);
		}
	}

	svc.ServiceStackResponseParser = function(data) {
		if (!has(data, 'payload')) {
			throw "Invalid response";
		}
		return data.payload;
	}

	svc.GetServiceResponse = function(data) {
		if (!has(data, 'payload')) {
			return data;
		}
		return data.payload;
	}

	return this;
}

export default ['$q', 'bpeHttpService', service];
