import angular from 'angular'
import bpeCoreHttpServices from '@libs/http-services'

const moduleId = 'bpeCoreLicensing'
export default moduleId

const module = angular.module(moduleId, [bpeCoreHttpServices])

/**
 * bpeLicensingService - Service that handles the licensing info for the current session.
 */
import { bpeLicensingService } from './bpe-licensing.service'
module.service('bpeLicensingService', bpeLicensingService)

import bpeLicensingTrial from './bpe-licensing-trial.component'
module.component('bpeLicensingTrial', bpeLicensingTrial)

/**
 * Global constants for the bpeCoreLicensing module
 */
module.constant('bpeCoreLicensingConstants', {
  licenseTypes: {
    TRIAL_PERIOD: 'Trial',
  },
  behaviors: {
    DISMISS: 'DISMISS',
    TIMEOUT: 'TIMEOUT',
  },
  events: {
    BPE_TRIAL_WARNING: 'event:bpe-trial-warning',
    BPE_NO_LICENSED: 'event:bpe-no-licensed',
  },
})
